import React from "react";
import {
  BannerPage,
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  ContentLanguage,
  CustomBuilderForm,
  SliderCustomHomes,
  ButtonSecondary,
  InternalLink,
  ButtonPrimary,
  Colors,
  ContentFluidImg,
  ContentTitle,
} from "../components";
import { Row, Col } from "antd";
import { graphql, useStaticQuery } from "gatsby";

const GetStarted = () => {
  const data = useStaticQuery(graphql`
    query {
      BannerImg: file(relativePath: { eq: "facades/cat3/Tanzanite.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Family2: file(relativePath: { eq: "other/family2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Family1: file(relativePath: { eq: "other/family1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFacadesJson {
        nodes {
          name
          storey
        }
      }
      allFloorPlansJson {
        nodes {
          name
          info {
            size
            storey
            bed
            bath
            carSpace
            living
          }
        }
      }
    }
  `);
  const facades = data.allFacadesJson.nodes;
  const floorPlans = data.allFloorPlansJson.nodes;
  return (
    <Layout>
      <SEO
        title="Get Started"
        description={"Start building your custom Grandeur Home"}
      />
      <BannerPage
        title={"Get Started"}
        subTitle={"Start building your custom Grandeur Home"}
      />
      <ContentFluidImg img={data["BannerImg"]} style={{ maxHeight: 660 }} />

      <ContainerContent>
        <ContainerContentFixed>
          <Row>
            <Col md={12} sm={24}>
              <CustomBuilderForm facades={facades} floorPlans={floorPlans} />
            </Col>
            <Col md={12} sm={24}>
              <div style={{ padding: "2rem" }}>
                <ContentTitle title="A Special Home, Custom built for you" />
                <div style={{ fontSize: "1.1rem" }}>
                  <p>
                    We’re always open to your ideas. We’re all about building a
                    home you will love.
                  </p>
                  <p>
                    There’s no need to keep dreaming of your dream home. Wake up
                    in the place you have always wanted with help from the{" "}
                    <b style={{ color: Colors.DarkGreen }}>
                      team at Grandeur Homes
                    </b>
                    .
                  </p>
                  <ContentFluidImg
                    img={data["Family2"]}
                    style={{ margin: "3rem 0" }}
                  />
                  <ContentTitle title="About Grandeur Homes" />

                  <p>
                    The Grandeur Homes team is the leader in custom built homes
                    that suit the owners down to the ground. From design and
                    plans to the finished Product, we work with you to bring
                    everything you want in a home to reality.
                  </p>
                  <p>
                    No Nasty surprises or compromises. We remember the most
                    important thing -{" "}
                    <b style={{ color: Colors.DarkGreen }}>
                      seeing you in the home you love.
                    </b>
                  </p>
                  <ContentFluidImg
                    img={data["Family1"]}
                    style={{ margin: "3rem 0" }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </ContainerContentFixed>
      </ContainerContent>

      <section>
        <ContainerContent style={{ backgroundColor: Colors.LightGray }}>
          <ContainerContentFixed>
            <ContentLanguage />
          </ContainerContentFixed>
        </ContainerContent>
      </section>
      <section>
        <ContainerContent
          style={{
            backgroundColor: "white",
            textAlign: "center",
            paddingTop: "0",
          }}
        >
          <SliderCustomHomes />
          <div style={{ marginTop: "32px" }}>
            <ButtonSecondary style={{ margin: "4px", minWidth: "250px" }}>
              <InternalLink url={InternalLinkTypes.VideosGallery}>
                Browse Videos Gallery
              </InternalLink>
            </ButtonSecondary>
            <ButtonPrimary style={{ margin: "4px", minWidth: "250px" }}>
              <InternalLink url={InternalLinkTypes.PhotosGallery}>
                Browse Photos Gallery
              </InternalLink>
            </ButtonPrimary>
          </div>
        </ContainerContent>
      </section>

      <section>
        <ContainerCta
          title={"Your future happiness is waiting"}
          subTitle={"Start building your custom Grandeur Home"}
          secondaryLink={InternalLinkTypes.Contact}
          secondaryText={"Contact Us"}
        />
      </section>
    </Layout>
  );
};

export default GetStarted;
